<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DPI_TYPES } from './store'
import { ROUTES as DPI_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import DpiTable from './DpiTable'
import DpiCommonFilters from './DpiCommonFilters'
import DpiEdit from './DpiEdit.vue'

export default {
  name: 'DpiList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': DpiTable,
    'common-filters': DpiCommonFilters,
    'component-detail': DpiEdit,
    'component-edit': DpiEdit
  },
  data () {
    return {
      DPI_ROUTES,
      title: this.$t('DPI'),
      rolePerm: ['miscellaneous_dpi_list'],
      actionEnablePermission: ['miscellaneous_dpi_enable'],
      actionDisablePermission: ['miscellaneous_dpi_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...DPI_TYPES.GENERIC.miscellaneous.dpi.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.DPI_ROUTES.DPI_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('miscellaneous_dpi_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: DPI_TYPES.GENERIC.miscellaneous.dpi.LIST.MUTATIONS.setSelectedList,
      setSelectAll: DPI_TYPES.GENERIC.miscellaneous.dpi.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...DPI_TYPES.GENERIC.miscellaneous.dpi.LIST.ACTIONS
    })
  }
}
</script>
