<template>
 <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-6 mb-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="changeName($event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as DPI_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'

export default {
  name: 'DpiEdit',
  mixins: [ThuxDetailPanelMixin],
  data () {
    return {
      pageName: 'Risk level',
      rolePerm: 'miscellaneous_dpi_retrieve',
      editRolePerm: 'miscellaneous_dpi_update'
    }
  },
  computed: {
    ...mapGetters({
      ...DPI_TYPES.GENERIC.miscellaneous.dpi.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...DPI_TYPES.GENERIC.miscellaneous.dpi.DETAIL.ACTIONS
    }),
    changeName (evt) {
      this.$set(this.form, 'name', evt)
      if (this.formEditable) {
        this.setSlug()
      }
    }
  },
  validations: {
    form: {
      name: { required }
    }
  }
}
</script>
